@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .jde-button {
        @apply m-2 p-2 bg-blue-400 text-gray-200 rounded-md;
    }

    .tooltip {
        @apply invisible absolute;
    }

    .has-tooltip:hover .tooltip {
        @apply visible z-50;
    }
/*    .tooltip .tooltip-text {*/
/*        @apply invisible p-4 absolute z-50 inline-block ml-4 text-sm w-1/2 rounded-lg bg-gray-400 text-white;*/
/*    }*/

/*    .tooltip:hover .tooltip-text {*/
/*        @apply visible;*/
/*    }*/


/*    .fieldLabel {*/
/*        @apply block text-sm font-medium leading-5 text-gray-700*/
/*    }*/
}

.amplify-tabs {
    display: none;
}
